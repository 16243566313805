@import "./variable.module";

.dashboardScreen {
  overflow-y: auto !important;
  height: calc(100vh - 103px);
  margin-top: 12px;
}

// full page loader css
.overlayLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 20%);
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;

  .spinner-border {
    --bs-spinner-border-width: 0.35em;
    --bs-spinner-width: 3.5rem;
    --bs-spinner-height: 3.5rem;
    border: var(--bs-spinner-border-width) solid $green;
    border-right-color: transparent;
  }
}

.bioFuel-map {
  --bs-modal-width: 100% !important;
  height: 100vh;
  overflow: hidden;
  margin: 0px !important;

  .resetBtn-zoom {
    right: 10px;
    bottom: 117px;
  }

  .highIndex {
    img {
      width: 23px;
    }
    span{
      color: $primary !important;
    }
  }

  .gm-style .gm-style-iw-c {
    background-color: $white !important;
    padding: 12px !important;
  }

  .gm-style-iw-ch {
    padding-top: 0px !important;
  }

  .gm-style-iw-chr {
    button {
      width: 22px !important;
      height: 22px !important;

      span {
        margin: 0px !important;
      }
    }
  }

  .gm-style .gm-style-iw-tc::after {
    background: $white !important;
  }

  .modal-header {
    display: none;
  }

  position: relative;

  .mapbtn-screen {
    position: absolute;
    top: 10px;
    right: 10px;
    border: transparent;
    background: transparent;

    img {
      padding: 0px !important;
      width: 30px;
    }
  }

}

.mapbtn-screen {
  position: absolute;
  top: 6px;
  right: 6px;
  border: transparent;
  background: transparent;

  img {
    padding: 0px !important;
    width: 20px;
  }
}

.ekosDashboard {
  .gm-style .gm-style-iw-c {
    background-color: $white !important;
    padding: 12px 15px 15px 12px !important
  }

  .gm-style .gm-style-iw-tc::after {
    background: $white !important;
    top: -2px;
    width: 25px;
  }

  .gm-style-iw-chr {
    .gm-style-iw-ch {
      padding-top: 0px !important;
    }


    button {
      height: 22px !important;
      width: 19px !important;

      span {
        margin: 0px !important;
      }
    }
  }
}



.spinner-border {
  --bs-spinner-border-width: 0.15em;
  --bs-spinner-animation-speed: 0.95s;
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  border: var(--bs-spinner-border-width) solid #215154;
  border-right-color: transparent;
}


.DashboardWrapper {
  width: 100%;
}

.loaderBtn {
  .spinner-border {
    border: var(--bs-spinner-border-width) solid #ffffff;
    border-right-color: transparent;
  }
}

.cursor {
  cursor: pointer;
}

// date time show css
.lates-update {
  p {
    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $black;
    }
  }
}

.graph-loader {
  height: 50vh;
}

.graph-loaderPie {
  height: 30vh;
}

.highcharts-background {
  fill: $info;
}

.logo-icon-name {
  @include dFlex;
  width: 30px;
  height: 30px;
  background: $primary;
  color: $white;
  border-radius: 50%;
  text-transform: uppercase;
}


a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint.gm-style-mtc-bbw {
  display: none;
}

.gm-style-cc {
  display: none !important;
}

.gm-svpc {
  display: none !important;
}

.verticaldata {
  position: absolute;
  left: -9.4375rem;
  transform: rotate(360deg);
  top: 47%;
  overflow-y: auto;

  .linesbefore {
    width: 4.1875rem;
    background-color: #EBEBF1;
    height: 1px;
  }
}

.table-bg {
  .table {
    --bs-table-hover-color: rgba(193, 211, 192, 0.4);
    --bs-table-hover-bg: rgba(193, 211, 192, 0.4);
  }
}

.primaryCard {
  height: 164px;
  padding: 0;
}

.pagination .page-link {
  font-size: 0.75rem !important;
}

.btnHeight {
  height: 45px;
}

.pagination {
  flex-wrap: wrap;
}

// datepicker css
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary !important;
}

.react-datepicker-wrapper {
  input {
    height: 40px;
    border: 1px solid $lightgreen;
    border-radius: 4px;
    background-color: $gray-100;
    outline: none;
    padding: 8px;
  }
}

.resetBtn-zoom {
  background-color: $white;
  color: $primary;
  border: 1px solid $white;
  position: absolute;
  z-index: 99;
  right: 10px;
  bottom: 102px;
  border-radius: 2px;
  width: 40px;
  height: 40px;

  img {
    padding-right: 0px !important;
  }
}

// select dropdown
.select-box {

  .css-b62m3t-container,
  .css-3iigni-container {
    min-width: 137px;

    .css-13cymwt-control,
    .css-16xfy0z-control {
      min-height: 45px;

      .css-1dimb5e-singleValue {
        text-overflow: inherit !important;
        text-transform: capitalize;
      }
    }

    .css-1u9des2-indicatorSeparator,
    .css-894a34-indicatorSeparator {
      display: none;
    }
  }

  .css-t3ipsp-control {
    min-height: 45px;
    border-color: $lightgreen !important;
    box-shadow: none;

    &:hover {
      border-color: $lightgreen !important;
      box-shadow: none !important;
    }
  }

  .paginationDropdown {
    max-width: 73px;
    min-width: 85px;

    div {
      text-align: left;
    }

    #react-select-5-listbox,
    #react-select-2-listbox {
      div {
        text-align: left;
      }
    }

    .css-13cymwt-control,
    .css-16xfy0z-control {
      min-height: 35px !important;
    }

    .css-t3ipsp-control {
      min-height: 35px !important;
    }
  }

  .yearDropdown,
  .quarterDropdown {
    min-width: 90px;
  }
}

.custom-select {
  @media (max-width: $md) {
    margin-top: 6px;
  }

  .css-13cymwt-control {
    min-height: 45px;
  }

  .css-f85sjx-option:hover {
    background-color: $lightgreen !important;
    color: $primary !important;
  }

  .css-tr4s17-option {
    background-color: $primary !important;
    color: hsl(0, 0%, 100%) !important;
    padding: 8px 12px;
    box-sizing: border-box;
  }

  .css-d7l1ni-option {
    background-color: $lightgreen !important;
    color: $primary !important;
  }
}