@import "../config/variable.module.scss";

.mainDashboard {
    background: $white;
    display: flex;

    .dashboard_Header {
        background-color: $white;
        border-bottom: 1px solid $gray-200;
        z-index: 4;
        width: 100%;

        .lower-logo {
            .logo-icon-name-wrapper {
                padding: 0 !important;

                img {
                    width: 116px;
                    height: 65px;
                    object-fit: contain;

                    @media screen and (max-width: $xxl ) {
                        height: 58px;
                        width: 60px;
                    }
                }
            }
        }

        .headerimg {
            img {
                max-width: 50px;
                max-height: 50px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                padding-right: 0px !important;
            }
        }

        .userHead_details {
            position: relative;

            p {
                color: $gray-600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 180px;
            }

            .settingProfile_dropdown {
                width: 175px;
                min-height: 100px;
                background-color: $white;
                border-radius: 8px;
                position: absolute;
                right: 0px;
                top: 57px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
                padding: 12px;
                display: none !important;

                .btn-deepgreen {
                    font-size: 0.87rem !important;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }

                .btn-gray {
                    background-color: $gray-100;
                }

                button {
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .logoWrapper {
            height: 74px;
            background-color: $primary;
            @include dFlex;
            padding: 12px 20px;

            img {
                padding-right: 0px !important;
                height: 34px;
            }
        }

        .user-detail {
            h4 {
                color: $gray-700;
            }

            img {
                width: 50px;
            }

            border-radius: 112px;
            border: 1px solid #F3F3F3;
            background: #FFF;
            box-shadow: 0px 0px 20px 10px #EAEAEA;
            padding: 5px 13px;
        }
    }
}