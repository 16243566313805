@import "./variable.module";
@import "./mixin.scss";

.btn-deepgreen {
    @include btn-deepgreen;
}
.btn-deepgreenLg {
    @include btn-deepgreen;
    min-width:240px;
}
.outlineBtn-deepgreen{
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    border-radius: 4px;
    white-space: nowrap;
}
.btn-lightGreen {
    @include btn-lightGreen;
}
.dangerBtn {
    @include dangerBtn;
}
.dangerbtn-outline{
    @include dangerBtn ;
    background-color: $gray-100;
    border: 1px solid $orange;
    color: $orange;
    &:hover {
        background-color: $gray-100;
        border: 1px solid $orange;
        color: $orange;
    }
}

.gray-btn {
    background-color: $gray-200;
    border: 1px solid $gray-200;
    color: $gray-700;
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advanced-btn {
    background: transparent;
    border: none;
    color: $dark;
    text-decoration: underline;
    font-weight: 500;
}
.btn-transparent {
    background-color: transparent;
    border: none;
    img {
        padding-right: 0px !important;
    }
}
.disabled-button{
    background: $gray-600;
    border: 1px solid $gray-600;
    border-radius: 4px;
    color: $gray-800;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 15px;
    height: 46px;
}    