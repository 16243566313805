@import "../config/variable.module.scss";
.ekosDashboard{
    .highIndex{
        span{
            color: $primary !important;
        }
        img{
            width: 20px;
        }
    }
    h1{
        color: $primary;
    }
    .key-matrics{
        background-color: $primary;
        border-radius: 8px;
        .matrics-card{
            border: 1px solid $lightgreen;
            border-radius: 4px;
            height: 164px;
            display: flex;
            .value{
                color: $green;
            }
        }
    }
    .mainGrayCards{
        table{
            th{
                font-size: 1rem;
                font-weight: 600;
            }
            th,td{
                padding: 16px 14px;
                border-bottom: 1px solid #c1d3c059;
                &:first-child{
                    padding-left: 36px;
                }
            }
            tbody{
                tr{
                    &:nth-child(odd){
                        background-color: $gray-200;
                    }
                }
                td{
                    font-size: 1rem;
                    font-weight: 500;
                }
            }
            
        }
    }
}